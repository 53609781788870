import React from 'react';
import Img from 'gatsby-image';
import { GridImgsEntity } from '../interfaces/IProject';

const Figure = ({ card }: { card: GridImgsEntity }) => {
  return (
    <figure className="figure w-100 grid-card">
      <Img
        fluid={card.url.childImageSharp.fluid}
        className="figure-img img-fluid rounded shadow-box"
        alt={card.alt}
      />
      <figcaption className="figure-caption fw-bold">{card.alt}</figcaption>
    </figure>
  );
};

export default Figure;
